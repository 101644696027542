import React from "react"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { RouterProvider } from "react-router-dom"
import router from "./router"
import { StoreProvider, useStoreActions } from "easy-peasy"
import store from "./store"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import ErrorPage from "./pages/ErrorPage"

function App() {
  const hydrateLogin = useStoreActions((action) => action.hydrateLogin)

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "id",
        autoDisplay: false,
      },
      "google_translate_element"
    )

    setTimeout(() => {
      const dropdown = document.querySelector(".goog-te-combo")
      if (dropdown) {
        // Menambahkan kelas Tailwind CSS ke dropdown
        dropdown.classList.add(
          "bg-[#fc8300]",
          "text-white",
          "w-48",
          "rounded-lg",
          "py-2",
          "px-4",
          "text-lg",
          "focus:outline-none",
          "focus:ring-none"
        )
      }
    }, 100)
  }

  React.useEffect(() => {
    hydrateLogin()

    // untuk google translate
    var addScript = document.createElement("script")
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [hydrateLogin])
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LesDSIkAAAAAM2lOe3vxsyaw9tjQxeBT1gJKsqj"
      container={{
        parameters: {
          badge: "bottomleft", // optional, default undefined
          theme: "dark", // optional, default undefined
        },
      }}
    >
      <div
        id="google_translate_element"
        className="z-50 absolute xl:bottom-6 lg:bottom-6 md:bottom-32 sm:bottom-32 bottom-32 left-0 input-lg max-h-5 focus:border-none"
      ></div>
      <StoreProvider store={store}>
        <ErrorPage />
        <RouterProvider router={router} />
      </StoreProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
