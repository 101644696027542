import React from "react"
import ListImages from "../assets"
import {
  ChevronDownIcon,
  EyeSlashIcon,
  EyeIcon,
  UserIcon,
} from "@heroicons/react/24/solid"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import OtpInput from "react-otp-input"

function MobileNavigation({
  profileData,
  user,
  setUser,
  mobileMenu,
  mobileHandler,
  inputHandler,
  mobileHide,
  dropdownMobile,
  setDropdownMobile,
  inputData,
  pass,
  setPass,
  pass2,
  setPass2,
  loginHandler,
  forgotSent,
  forgotPassword,
  setForgotSent,
  otpHandler,
  registerHandler,
  otpCode,
  setOtpCode,
}) {
  const navigate = useNavigate()
  return (
    <div>
      {user ? (
        <div
          className={`${
            dropdownMobile === "profile"
              ? "max-h-[70vh] pt-[60px]"
              : "max-h-0 pt-0 overflow-hidden"
          } transition-all fixed z-[1020] w-full left-0`}
        >
          <div
            className={`${
              dropdownMobile === "profile"
                ? "max-h-[70vh] pt-[60px] overflow-y-auto"
                : "max-h-0 pt-0 overflow-hidden"
            } transition-all fixed bg-semiblack rounded-md left-0 w-full text-white`}
          >
            <div className="p-3">
              {profileData?.profile?.avatar !== "" ? (
                <img
                  alt=""
                  className="rounded-full h-[6rem] w-[6rem]"
                  src={profileData?.profile?.avatar}
                />
              ) : (
                <div className="rounded-full p-5 bg-background justify-center items-center flex w-[5rem] h-[5rem] mx-auto">
                  <UserIcon />
                </div>
              )}
              <div className="flex flex-col space-y-1 pb-3 border-b border-[#303030] items-center">
                <div className="font-semibold">{profileData?.email}</div>
                <div>{profileData?.profile?.badge?.name} Member</div>
                <div>Poinmu : {profileData?.balance}</div>
              </div>
              <div
                className="mt-3"
                onClick={() => {
                  navigate("/profile/edit-profile")
                  setDropdownMobile("")
                }}
              >
                Edit Profile
              </div>
              <div className="bg-[#303030] my-3 w-full h-[1px]"></div>
              <div>
                <div
                  onClick={() => {
                    navigate("/profile/pembelian")
                    setDropdownMobile("")
                  }}
                >
                  Pesananmu
                </div>
                <div className="ml-3 flex flex-col space-y-3 mt-3">
                  <div
                    onClick={() => {
                      navigate("/profile/pembelian")
                      setDropdownMobile("")
                    }}
                  >
                    Your Ticket
                  </div>
                  <div
                    onClick={() => {
                      navigate("/profile/pembelian")
                      setDropdownMobile("")
                    }}
                  >
                    Your Photo
                  </div>
                  <div
                    onClick={() => {
                      navigate("/photos/your-collection")
                      setDropdownMobile("")
                    }}
                  >
                    Your Collection
                  </div>
                </div>
              </div>
              <div className="bg-[#303030] my-3 w-full h-[1px]"></div>
              <div
                className="mt-3"
                onClick={() => {
                  navigate("/profile/gallery-photo")
                  setDropdownMobile("")
                }}
              >
                Gallery Photo
              </div>
              <div className="bg-[#303030] my-3 w-full h-[1px]"></div>
              <div
                onClick={() => {
                  setUser(false)
                  localStorage.clear("token")
                  localStorage.clear("user")
                  toast("Logged Out")
                  navigate("/")
                }}
                className="text-red-500 cursor-pointer"
                x
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Mobile Menu  */}
          <div
            className={`${
              mobileMenu
                ? "max-h-[100rem] pt-[60px]"
                : "max-h-0 pt-0 overflow-hidden"
            } transition-all fixed top-0 z-[1020] bg-[#191919] text-white w-full`}
          >
            <div className="px-5">
              <div
                onClick={() => mobileHandler("event")}
                className="flex items-center space-x-2 py-4 border-b border-background"
              >
                <div onClick={() => mobileHandler("event")}>Event</div>
                <ChevronDownIcon
                  onClick={() => mobileHandler("event")}
                  className={`${
                    mobileHide.event ? "rotate-[180deg]" : "rotate-0"
                  } transition-all text-white h-[1rem] w-auto`}
                />
              </div>
              <div
                className={`${
                  mobileHide.event
                    ? "max-h-[30rem]"
                    : "max-h-0 pt-0 overflow-hidden"
                } transition-all`}
              >
                <div className="flex items-center space-x-2 py-4 border-b border-background">
                  <a href="/#terpopuler">Event Terpopuler</a>
                </div>
                <div className="flex items-center space-x-2 py-4 border-b border-background">
                  <a href="/#terbaru">Event Terbaru</a>
                </div>
                <div className="flex items-center space-x-2 py-4 border-b border-background">
                  <a href="/#rekomendasi">Event Rekomendasi</a>
                </div>
              </div>
              <div className="flex items-center space-x-2 py-4 border-b border-background">
                <a href="/#hotoffer">Hot Offer</a>
              </div>
              <div className="flex items-center space-x-2 py-4 border-b border-background">
                <a href="/#berita">GH News</a>
              </div>
              <div className="flex items-center space-x-2 py-4 border-b border-background">
                <div>Our Venue</div>
              </div>
              <div className="flex items-center space-x-2 py-4 border-b border-background">
                <img src={ListImages.Indonesia} alt="" />
                <div>Indonesia</div>
                <ChevronDownIcon className="h-[1rem] w-auto text-white" />
              </div>
              <div
                id="aplikasi"
                onClick={() => mobileHandler("aplikasi")}
                className="flex items-center space-x-2 py-3 text-primary"
              >
                <div>Download Aplikasi</div>
                <ChevronDownIcon
                  className={`h-[1rem] w-auto ${
                    mobileHide.aplikasi ? "rotate-[180deg]" : "rotate-0"
                  } transition-all`}
                />
              </div>
              <div
                className={`${
                  mobileHide.aplikasi
                    ? "max-h-[30rem]"
                    : "max-h-0 pt-0 overflow-hidden"
                } transition-all`}
              >
                <div className="mt-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
                <div className="flex justify-center space-x-2 mt-3 pb-5">
                  <img src={ListImages.Appstore} alt="" />
                  <img src={ListImages.Playstore} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* Mobile Login  */}
          <div
            className={`${
              dropdownMobile === "masuk"
                ? "max-h-[30rem] pt-[60px]"
                : "max-h-0 pt-0 overflow-hidden"
            } lg:hidden transition-all bg-semiblack text-white fixed w-full z-[1020]`}
          >
            <div className="flex flex-col space-y-5 p-5">
              <div>Selamat Datang di GueHadir.id</div>
              <div className="flex">
                Belum memiliki akun GueHadir.id?{" "}
                <span
                  onClick={() => setDropdownMobile("register")}
                  className="text-primary ml-1 whitespace-nowrap"
                >
                  Sign Up Here
                </span>
              </div>
              <input
                value={inputData.email}
                onChange={(e) => inputHandler(e)}
                id="email"
                className="p-2 rounded-md bg-background outline-none border-none"
                placeholder="Email"
              />
              <div className="bg-background p-2 rounded-md flex items-center justify-between">
                <input
                  value={inputData.password}
                  onChange={(e) => inputHandler(e)}
                  id="password"
                  autoComplete="off"
                  autoCorrect="off"
                  type={pass}
                  className="flex-1 bg-transparent outline-none border-none "
                  placeholder="Kata Sandi"
                />
                {pass !== "" ? (
                  <EyeIcon
                    onClick={() => setPass("")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                ) : (
                  <EyeSlashIcon
                    onClick={() => setPass("password")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" />
                  <div>Tetap Masuk</div>
                </div>
                <div
                  onClick={() => setDropdownMobile("forgotpassword")}
                  className="text-primary"
                >
                  Lupa Password ?
                </div>
              </div>
              <div>
                By continuing past this page, you agree to the{" "}
                <span className="text-primary">Terms of Use</span> and
                understand that information will be used as described in our{" "}
                <span className="text-primary">Privacy Policy</span>
              </div>
              <button
                onClick={() => loginHandler()}
                className="p-2 rounded-md bg-primary text-black"
              >
                Masuk
              </button>
            </div>
          </div>

          {/* Mobile Register  */}
          <div
            className={`${
              dropdownMobile === "register"
                ? "max-h-[100rem] overflow-y-auto pt-[60px]"
                : "max-h-0 pt-0 overflow-hidden"
            } lg:hidden transition-all bg-semiblack text-white fixed top-0 w-full z-[1020]`}
          >
            <div className="flex flex-col space-y-5 p-5">
              <div>Sign Up Account</div>
              <div className="flex">
                Sudah memiliki akun GueHadir.id?{" "}
                <span
                  onClick={() => setDropdownMobile("masuk")}
                  className="text-primary ml-1 whitespace-nowrap"
                >
                  Masuk disini
                </span>
              </div>
              <input
                onChange={(e) => inputHandler(e)}
                id="name"
                className="p-2 rounded-md bg-background outline-none border-none"
                placeholder="Nama Lengkap"
              />
              <input
                onChange={(e) => inputHandler(e)}
                id="telephone"
                className="p-2 rounded-md bg-background outline-none border-none"
                placeholder="No. Telephone"
              />
              <input
                onChange={(e) => inputHandler(e)}
                id="email"
                className="p-2 rounded-md bg-background outline-none border-none"
                placeholder="Email"
              />
              <div className="bg-background p-2 rounded-md flex items-center justify-between">
                <input
                  onChange={(e) => inputHandler(e)}
                  id="password"
                  autoComplete="off"
                  autoCorrect="off"
                  type={pass}
                  className="flex-1 bg-transparent outline-none border-none "
                  placeholder="Kata Sandi"
                />
                {pass !== "" ? (
                  <EyeIcon
                    onClick={() => setPass("")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                ) : (
                  <EyeSlashIcon
                    onClick={() => setPass("password")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                )}
              </div>
              <div>*Kata sandi harus terdiri dari 10 karakter</div>
              <div className="bg-background p-2 rounded-md flex items-center justify-between">
                <input
                  onChange={(e) => inputHandler(e)}
                  id="password2"
                  type={pass2}
                  autoComplete="off"
                  autoCorrect="off"
                  className="flex-1 bg-transparent outline-none border-none "
                  placeholder="Konfirmasi Kata Sandi"
                />
                {pass2 !== "" ? (
                  <EyeIcon
                    onClick={() => setPass2("")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                ) : (
                  <EyeSlashIcon
                    onClick={() => setPass2("password")}
                    className="h-[1rem] w-auto text-gray-400"
                  />
                )}
              </div>
              <div>*Kedua kata sandi harus sama</div>
              <div>
                By continuing past this page, you agree to the{" "}
                <span className="text-primary">Terms of Use</span> and
                understand that information will be used as described in our{" "}
                <span className="text-primary">Privacy Policy</span>
              </div>
              <button
                onClick={() => otpHandler()}
                className="p-2 rounded-md bg-primary text-black"
              >
                Sign Up
              </button>
            </div>
          </div>

          {/* Verifikasi */}
          <div
            className={`${
              dropdownMobile === "verifikasi"
                ? "max-h-[100rem] overflow-y-auto pt-[60px]"
                : "max-h-0 pt-0 overflow-hidden"
            } lg:hidden transition-all bg-semiblack text-white fixed top-0 w-full z-[1020]`}
          >
            <div className="p-5 flex flex-col space-y-5">
              <div className="text-[1.2rem] font-semibold">Verifikasi Akun</div>
              <div>
                Kami telah mengirimkan kode verifikasi ke{" "}
                <span className="text-primary font-semibold">
                  {inputData.email}
                </span>{" "}
                mohon periksa email kamu.
              </div>
              <div>
                <OtpInput
                  inputStyle="inputStyle"
                  value={otpCode}
                  onChange={(otp) => setOtpCode(otp)}
                  numInputs={4}
                  separator={
                    <span className="w-[20px] flex justify-center">.</span>
                  }
                />
              </div>
              <button
                onClick={() => registerHandler()}
                className="text-center p-3 rounded-md bg-primary text-black font-semibold"
              >
                Verifikasi
              </button>
              <div>
                Tidak mendapatkan kode verifikasi ?{" "}
                <span
                  onClick={() => otpHandler()}
                  className="text-primary font-semibold cursor-pointer"
                >
                  Kirim Ulang
                </span>
              </div>
            </div>
          </div>

          {/* Forgot Password  */}
          <div
            className={`${
              dropdownMobile === "forgotpassword"
                ? "max-h-[30rem] pt-[60px]"
                : "max-h-0 pt-0 overflow-hidden"
            } transition-all bg-black text-white fixed top-0 w-full z-[1020]`}
          >
            <div className="flex flex-col space-y-5 p-5">
              <div>
                {forgotSent
                  ? "Tautan perubahan terkirim"
                  : "Lupa password Anda?"}
              </div>
              {forgotSent ? (
                <div>
                  {" "}
                  Kami telah mengirimkan link reset password ke{" "}
                  <span className="text-primary">{inputData.email}</span> mohon
                  periksa email kamu.{" "}
                </div>
              ) : (
                <div>
                  Tidak masalah. Masukkan alamat email Anda dan kami akan
                  mengirimkan instruksi untuk mengganti password.
                </div>
              )}
              {!forgotSent && (
                <input
                  onChange={(e) => inputHandler(e)}
                  id="email"
                  className="p-2 rounded-md bg-background outline-none border-none"
                  placeholder="Email"
                />
              )}
              {forgotSent ? (
                <button
                  onClick={() => {
                    setDropdownMobile("")
                  }}
                  className="p-2 rounded-md bg-primary text-black"
                >
                  Kembali
                </button>
              ) : (
                <button
                  onClick={() => forgotPassword()}
                  className="p-2 rounded-md bg-primary text-black"
                >
                  Kirim
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MobileNavigation
