import Appstore from "./appstore.png"
import Background from "./background.png"
import Banner from "./banner.png"
import Banner2 from "./banner2.png"
import Illustration1 from "./illustration-1.png"
import Illustration2 from "./illustration-2.png"
import Illustration3 from "./illustration-3.png"
import Indonesia from "./indonesia.svg"
import Logo from "./logo.png"
import Guehadir from "./guehadir.svg"
import Menu from "./menu.svg"
import Playstore from "./playstore.png"
import Qrcode from "./qrcode.png"
import User from "./user.svg"
import USA from "./usa.svg"
import Ticket from "./ticket.svg"
import Clock from "./clock.svg"

import SeatingPlan from "./seatingPlan.png"

import Bandung from "./bandung.png"
import Semarang from "./semarang.png"
import Yogyakarta from "./yogyakarta.png"
import Malang from "./malang.png"
import Offer1 from "./offer1.png"
import Offer2 from "./offer2.png"

//Payment
import bca from "./pembayaran/bca.png"
import bni from "./pembayaran/bni.png"
import bri from "./pembayaran/bri.png"
import mandiri from "./pembayaran/mandiri.png"
import permata from "./pembayaran/permata.png"
import alfamart from "./pembayaran/alfamart.png"
import indomaret from "./pembayaran/indomaret.jpg"
import cimb from "./pembayaran/cimbniaga.png"
import danamon from "./pembayaran/danamon.png"
import credit from "./pembayaran/credit.png"
import gopay from "./pembayaran/gopay.png"
import shopee from "./pembayaran/shopee.png"
import linkaja from "./pembayaran/linkaja.png"
import jenius from "./pembayaran/jenius.png"
import qris from "./pembayaran/qris.png"
// Social Media
import tiktok from "./tiktok.svg"
import instagram from "./instagram.svg"
import facebook from "./facebook.svg"
import whatsapp from "./whatsapp.svg"
import twitter from "./twitter.svg"

// Social Media Colored
import tiktokColored from "./socialmedia/tiktok.svg"
import facebookColored from "./socialmedia/facebook.svg"
import whatsappColored from "./socialmedia/whatsapp.svg"
import telegramColored from "./socialmedia/telegram.svg"

import Signout from "./signout.svg"

import GHCoin from "./GH_Coin.svg"

// banner partai
import BannerPartai from "./banner_partai.jpg"
import BannerPartaiSukses from "./banner_partai_sukses.jpg"

const ListImages = {
  Ticket,
  Appstore,
  Bandung,
  Semarang,
  Yogyakarta,
  Malang,
  Background,
  Banner,
  Banner2,
  Offer1,
  Offer2,
  Illustration1,
  Illustration2,
  Illustration3,
  Indonesia,
  USA,
  Logo,
  Menu,
  Playstore,
  Qrcode,
  User,
  SeatingPlan,
  Clock,
  Guehadir,
  //Payment
  bca,
  bni,
  bri,
  mandiri,
  permata,
  indomaret,
  alfamart,
  cimb,
  danamon,
  credit,
  gopay,
  shopee,
  linkaja,
  jenius,
  qris,
  //Social Media
  tiktok,
  facebook,
  instagram,
  whatsapp,
  twitter,
  tiktokColored,
  facebookColored,
  whatsappColored,
  telegramColored,

  Signout,

  GHCoin,

  //banner partai
  BannerPartai,
  BannerPartaiSukses,
}

export default ListImages
