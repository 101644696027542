import React from "react"
import Logo from "../assets/logo.png"
import Indonesia from "../assets/indonesia.svg"
import {
  ChevronDownIcon,
  CheckIcon,
  EyeSlashIcon,
  EyeIcon,
} from "@heroicons/react/24/solid"
import Menu from "../assets/menu.svg"
import User from "../assets/user.svg"
import { useState } from "react"
import ListImages from "../assets"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useEffect } from "react"
import MobileNavigation from "./mobileNavigation"
import { UserIcon } from "@heroicons/react/24/outline"
import { useContext } from "react"
import { GeneralContext } from "../context/context"
import OtpInput from "react-otp-input"
import { GHButton } from "./GHSystem"
import { axiosWithInterceptor, catchAxiosError } from "../utils"
import { useStoreActions, useStoreState } from "easy-peasy"

function Navigation() {
  const [loading, setLoading] = useState(false)
  const { user, setUser } = useContext(GeneralContext)
  const [rememberMe, setRememberMe] = useState(false)
  const [profileData, setProfileData] = useState({})
  const validateLogin = useStoreActions((action) => action.validateLogin)
  const togglePopover = useStoreActions((action) => action.togglePopover)
  const resetStore = useStoreActions((action) => action.resetStore)
  const getCart = useStoreActions((action) => action.getCart)
  const getGallery = useStoreActions((action) => action.getGallery)
  const popover = useStoreState((action) => action.popover)
  const loggedIn = useStoreState((action) => action.loggedIn)
  const [dropdown, setDropdown] = useState("")
  const [mobileMenu, setMobileMenu] = useState(false)
  const [dropdownMobile, setDropdownMobile] = useState("")
  const [inputData, setInputData] = useState({
    name: "",
    telephone: "",
    email: "",
    password: "",
    password2: "",
  })

  const [otpCode, setOtpCode] = useState("")

  // Password Visible & Invisible
  const [pass, setPass] = useState("password")
  const [pass2, setPass2] = useState("password")

  const [forgotSent, setForgotSent] = useState(false)
  const [mobileHide, setMobileHide] = useState({})

  // Get User Info
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token")

      if (token) {
        const customAxios = axiosWithInterceptor()
        await customAxios
          .get(`${process.env.REACT_APP_BASE_URL}/apps/user-profile`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (response) => {
            if (response.data.user) {
              setProfileData(response.data.user)
              setUser(true)

              //get cart data
              await getCart()
              //get gallery
              await getGallery()
            } else {
              setUser(false)
              localStorage.clear()
            }
          })
          .catch((error) => console.log(error))
      } else {
        setUser(false)
      }
    }
    if (loggedIn) {
      fetchData()
    }
  }, [loggedIn])

  const mobileHandler = (identifier) => {
    setMobileHide((prevState) => {
      return {
        ...prevState,
        [identifier]: !prevState[identifier],
      }
    })
  }

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      }
    })
  }

  const otpHandler = async () => {
    if (
      inputData.name &&
      inputData.telephone &&
      inputData.email &&
      inputData.password &&
      inputData.password2 === inputData.password
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/request-otp`,
          {
            email: inputData.email,
          },
          {
            timeout: 2000,
          }
        )
        .then((response) => {
          toast(response.data.message)
          setDropdown("verifikasi")
          setDropdownMobile("verifikasi")
        })
        .catch(catchAxiosError)
    } else toast("Please input valid data")
  }

  const registerHandler = async () => {
    if (
      inputData.name &&
      inputData.telephone &&
      inputData.email &&
      inputData.password &&
      inputData.password2 === inputData.password &&
      otpCode.length === 4
    ) {
      setLoading(true)
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/register`,
          {
            name: inputData.name,
            telephone: inputData.telephone,
            email: inputData.email,
            password: inputData.password,
            otp: Number(otpCode),
          },
          {
            timeout: 2000,
          }
        )
        .then((response) => {
          localStorage.setItem("token", response.data.accessToken)
          localStorage.setItem("user", JSON.stringify(response.data.user))
          setDropdown("")
          setDropdownMobile("")
          toast(response.data.message)
          validateLogin({ user: response.data.user })
          // setInputData({
          //   name: "",
          //   telephone: "",
          //   email: "",
          //   password: "",
          //   password2: "",
          // });
        })
        .catch(catchAxiosError)
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast("Please input valid data")
    }
  }

  const loginHandler = async () => {
    if (inputData.email && inputData.password) {
      setLoading(true)
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/login`,
          {
            username: inputData.email,
            password: inputData.password,
            remember_me: rememberMe,
          },
          {
            timeout: 2000,
          }
        )
        .then(({ data }) => {
          if (rememberMe) {
            localStorage.setItem("token", data.accessToken)
            localStorage.setItem("refreshToken", data.refreshToken)
          } else {
            localStorage.setItem("token", data.accessToken)
          }
          localStorage.setItem("user", JSON.stringify(data.user))
          setProfileData(data.user)
          validateLogin({ user: data.user })
          setUser(true)
          setInputData({
            name: "",
            telephone: "",
            email: "",
            password: "",
            password2: "",
          })
          toast(data.message)
          // setTimeout(() => {
          // }, 3000);
        })
        .catch(catchAxiosError)
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast("Please input valid data")
    }
  }

  const forgotPassword = async () => {
    setLoading(true)
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/apps/forgot`, {
        email: inputData.email,
        page_url: `${window.location.origin}/reset-password`,
      })
      .then((response) => {
        toast(response.data.message)
        setForgotSent(true)
      })
      .catch((error) => {
        toast(error.response.data.message)
      })
    setLoading(false)
  }

  const navigate = useNavigate()

  // const dropdownHandler = (identifier) => {
  //   if (dropdown !== identifier) {
  //     setDropdown(identifier);
  //   } else {
  //     setDropdown("");
  //   }
  // };

  const dropdownMobileHander = (identifier) => {
    if (dropdownMobile !== identifier) {
      setDropdownMobile(identifier)
    } else {
      setDropdownMobile("")
    }
  }

  return (
    <>
      <ToastContainer
        progressStyle={{ background: "#FC8300" }}
        autoClose={1500}
        toastClassName="bg-black"
        position="top-center"
        toastStyle={{ background: "black", color: "white" }}
      />

      <MobileNavigation
        user={user}
        setUser={setUser}
        mobileMenu={mobileMenu}
        mobileHandler={mobileHandler}
        inputHandler={inputHandler}
        mobileHide={mobileHide}
        dropdownMobile={dropdownMobile}
        setDropdownMobile={setDropdownMobile}
        dropdownMobileHander={dropdownMobileHander}
        profileData={profileData}
        inputData={inputData}
        pass={pass}
        setPass={setPass}
        pass2={pass2}
        setPass2={setPass2}
        loginHandler={loginHandler}
        forgotSent={forgotSent}
        forgotPassword={forgotPassword}
        setForgotSent={setForgotSent}
        otpHandler={otpHandler}
        registerHandler={registerHandler}
        otpCode={otpCode}
        setOtpCode={setOtpCode}
      />

      {/* Navigation  gh-black-300 */}
      <div className=" w-full flex justify-center bg-gh-black-300  text-white top-0 z-[1020] sticky">
        <div className="container py-6 px-5 lg:px-0 flex-1 flex justify-between items-center">
          <div className="flex items-center gap-5 ">
            <img
              onClick={() =>
                user
                  ? dropdownMobileHander("profile")
                  : setMobileMenu(!mobileMenu)
              }
              className="lg:hidden w-6 h-6"
              src={Menu}
              alt=""
            />
            <Link to="/">
              <img
                // onClick={() => navigate("/")}
                className="cursor-pointer h-6 w-auto lg:h-auto"
                src={ListImages.Guehadir}
                alt=""
              />
            </Link>
            <div className="hidden lg:flex items-center space-x-3">
              <div className="relative flex items-center space-x-1">
                <div
                  data-toggle-popover="event"
                  onClick={(e) => {
                    togglePopover(e)
                  }}
                  className=" flex items-center space-x-1 cursor-pointer"
                >
                  <div>Event</div>
                  <ChevronDownIcon
                    className={`${
                      popover === "event" ? "rotate-[180deg]" : "rotate-0"
                    } transition-all h-[1rem] w-auto`}
                  />
                </div>
                <div
                  className={`${
                    popover === "event"
                      ? "max-h-[20rem]"
                      : "max-h-0 overflow-hidden"
                  } transition-all rounded-md w-[250px] absolute top-[150%] left-[-50%] bg-[#191919] z-[999]`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex flex-col p-2">
                    <a
                      href="/#terbaru"
                      className="py-2 border-b border-[#303030] whitespace-nowrap"
                    >
                      Event Terbaru
                    </a>
                    <a
                      href="/#terpopuler"
                      className="py-2 border-b border-[#303030] whitespace-nowrap"
                    >
                      Event Terpopuler
                    </a>
                    <a href="/#rekomendasi" className="py-2 whitespace-nowrap">
                      Event Rekomendasi
                    </a>
                  </div>
                </div>
              </div>
              {/* <a href="/#hotoffer">Hot Offer</a> */}
              <a href="/#berita">GH News</a>
              <div>Our Venue</div>
            </div>
          </div>
          <div className="hidden lg:flex items-center space-x-5">
            {/* <div className="relative">
              <div
                onClick={() => dropdownHandler("aplikasi")}
                className=" flex items-center space-x-1 text-primary cursor-pointer"
              >
                <div>Download Aplikasi</div>
                <ChevronDownIcon
                  className={`${
                    dropdown === "aplikasi" ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1rem] w-auto`}
                />
              </div>
              <div
                className={`${
                  dropdown === "aplikasi"
                    ? "max-h-[100rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all bg-semiblack rounded-md absolute top-[150%] left-0 z-[999]`}
              >
                <div className="p-4 flex flex-col space-y-3">
                  <div className="text-[0.9rem]">
                    Pindai kode QR untuk mengunduh Aplikasi{" "}
                    <span className="text-primary">GueHadir.id</span> di
                    handphone mu!
                  </div>
                  <img src={ListImages.Qrcode} alt="" />
                  <img src={ListImages.Playstore} alt="" />
                  <img src={ListImages.Appstore} alt="" />
                </div>
              </div>
            </div> */}
            {/* <div className="relative">
              <div
                data-toggle-popover="language"
                onClick={(e) => togglePopover(e)}
                className=" flex items-center space-x-1 cursor-pointer"
              >
                <img src={Indonesia} alt="" />
                <div>Indonesia</div>
                <ChevronDownIcon
                  className={`${
                    popover === "language" ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1rem] w-auto`}
                />
              </div>
              <div
                className={`${
                  popover === "language"
                    ? "max-h-[30rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all absolute bg-semiblack w-[300px] rounded-md right-0 top-[150%] z-[999]`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="p-2">
                  <div className="flex flex-col cursor-pointer">
                    <div className="flex items-center justify-between py-3 border-b border-[#303030]">
                      <div className="flex items-center space-x-1">
                        <img src={ListImages.Indonesia} alt="" />
                        <div>Indonesia</div>
                      </div>
                      <div className="bg-primary rounded-full p-1 flex items-center justify-center">
                        <CheckIcon className="h-[1rem] w-auto text-black" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col cursor-pointer">
                    <div className="flex items-center justify-between py-3">
                      <div className="flex items-center space-x-1">
                        <img src={ListImages.USA} alt="" />
                        <div>English (USA)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* User Check  */}
            {user ? (
              <div className="relative">
                <div
                  data-toggle-popover="profile"
                  onClick={(e) => togglePopover(e)}
                  className=" flex space-x-3 cursor-pointer"
                >
                  <div className="flex flex-col">
                    <div className="text-right">{profileData?.email}</div>
                    <div className="text-right">
                      {profileData?.profile?.badge?.name ?? "Member"}
                    </div>
                  </div>
                  {profileData?.profile?.avatar !== "" ? (
                    <img
                      alt=""
                      className="rounded-full h-[3rem] w-[3rem]"
                      src={profileData.profile?.avatar}
                    />
                  ) : (
                    <div className="rounded-full p-2 bg-semiblack justify-center items-center flex w-[3em] h-[3rem]">
                      <UserIcon className="h-[1.5rem] w-auto" />
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    popover === "profile"
                      ? "max-h-[30rem]"
                      : "max-h-0 overflow-hidden"
                  } transition-all absolute bg-semiblack rounded-md top-[130%] right-0 w-[300px]`}
                >
                  <div className="p-3 cursor-pointer">
                    <button
                      className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                      onClick={() => {
                        navigate("/profile/profile")
                        setDropdown("")
                      }}
                    >
                      Edit Profile
                    </button>
                    <div className="bg-[#303030] my-2 w-full h-[1px]"></div>
                    <div>
                      <button
                        className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                        onClick={() => {
                          navigate("/profile/payment")
                          setDropdown("")
                        }}
                      >
                        Pesananmu
                      </button>
                      <div className="ml-3 flex flex-col space-y-3 mt-3">
                        <button
                          className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                          onClick={() => {
                            navigate("/profile/payment/ticket")
                            setDropdown("")
                          }}
                        >
                          Your Ticket
                        </button>
                        <button
                          className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                          onClick={() => {
                            navigate("/profile/payment/photo")
                            setDropdown("")
                          }}
                        >
                          Your Photo
                        </button>
                        <button
                          className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                          onClick={() => {
                            navigate("/photos/your-collection")
                            setDropdown("")
                          }}
                        >
                          Your Collection
                        </button>
                      </div>
                    </div>
                    <div className="bg-[#303030] my-2 w-full h-[1px]"></div>
                    <button
                      className="hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                      onClick={() => {
                        navigate("/profile/gallery-photo")
                        setDropdown("")
                      }}
                    >
                      Gallery Photo
                    </button>
                    <div className="bg-[#303030] my-2 w-full h-[1px]"></div>
                    <button
                      onClick={() => {
                        setUser(false)
                        localStorage.clear("token")
                        localStorage.clear("user")
                        validateLogin({ user: null })
                        toast("Logged Out")
                        resetStore()
                        navigate("/")
                      }}
                      className="text-red-500 hover:text-white cursor-pointer hover:bg-orange-700 rounded-sm duration-150 w-full flex px-2"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="relative">
                  <button
                    data-toggle-popover="masuk"
                    onClick={(e) => togglePopover(e)}
                    className=" py-2"
                  >
                    Masuk
                  </button>
                  <div
                    className={`${
                      popover === "masuk"
                        ? "max-h-[100rem]"
                        : "max-h-0 overflow-hidden"
                    } absolute right-[-100%] bg-semiblack z-[999]`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className=" p-4 rounded-md flex flex-col text-[0.75rem] w-[350px]">
                      <div className="text-[1.2rem] font-semibold">
                        Selamat Datang di GueHadir.id
                      </div>
                      <div className="text-[0.75rem] mt-3">
                        Belum memilik akun Guehadir.id?{" "}
                        <span
                          data-toggle-popover="daftar"
                          onClick={(e) => togglePopover(e)}
                          className=" text-primary cursor-pointer"
                        >
                          Sign Up Here
                        </span>
                      </div>
                      <input
                        onChange={(e) => inputHandler(e)}
                        id="email"
                        value={inputData.email}
                        className="p-2 rounded-md bg-background mt-5 outline-none"
                        placeholder="Email"
                      />
                      <div className="p-2 rounded-md bg-background mt-5 flex justify-between">
                        <input
                          onChange={(e) => inputHandler(e)}
                          id="password"
                          autoComplete="off"
                          autoCorrect="off"
                          value={inputData.password}
                          type={pass}
                          className="flex-1 bg-transparent outline-none"
                          placeholder="Kata Sandi"
                        />
                        {pass !== "" ? (
                          <EyeIcon
                            onClick={() => setPass("")}
                            className="h-[1rem] w-auto text-gray-400  cursor-pointer"
                          />
                        ) : (
                          <EyeSlashIcon
                            onClick={() => setPass("password")}
                            className="h-[1rem] w-auto text-gray-400 cursor-pointer"
                          />
                        )}
                      </div>

                      <div className="flex items-center justify-between mt-5">
                        <div className="flex items-center space-x-1">
                          <input
                            onClick={() => setRememberMe(!rememberMe)}
                            type="checkbox"
                          />
                          <div>Tetap Masuk</div>
                        </div>
                        <div
                          data-toggle-popover="forgotpassword"
                          onClick={(e) => {
                            togglePopover(e)
                            setForgotSent(false)
                          }}
                          className=" text-primary cursor-pointer"
                        >
                          Lupa Password ?
                        </div>
                      </div>
                      <div className="mt-5">
                        By continuing past this page, you agree to the{" "}
                        <span className="text-primary">Terms of Use</span> and
                        understand that information will be used as described in
                        our <span className="text-primary">Privacy Policy</span>
                        .
                      </div>
                      <button
                        onClick={() => loginHandler()}
                        className="text-center text-black p-2 bg-primary rounded-md w-full mt-5 font-semibold"
                      >
                        {loading ? "Loading..." : "Masuk"}
                      </button>
                    </div>
                  </div>

                  {/* Forgot Password  */}
                  <div
                    className={`${
                      popover === "forgotpassword"
                        ? "max-h-[100rem]"
                        : "max-h-0 overflow-hidden"
                    } absolute right-[-100%] bg-semiblack z-[999] `}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className="p-4 rounded-md flex flex-col text-[0.75rem] w-[350px] space-y-3">
                      <div>
                        {forgotSent
                          ? "Tautan perubahan terkirim"
                          : "Lupa password Anda?"}
                      </div>
                      {forgotSent ? (
                        <div>
                          {" "}
                          Kami telah mengirimkan link reset password ke{" "}
                          <span className="text-primary">
                            {inputData.email}
                          </span>{" "}
                          mohon periksa email kamu.{" "}
                        </div>
                      ) : (
                        <div>
                          Tidak masalah. Masukkan alamat email Anda dan kami
                          akan mengirimkan instruksi untuk mengganti password.
                        </div>
                      )}
                      {!forgotSent && (
                        <input
                          onChange={(e) => inputHandler(e)}
                          id="email"
                          className="p-2 rounded-md bg-background outline-none border-none"
                          placeholder="Email"
                        />
                      )}
                      {forgotSent ? (
                        <button
                          onClick={(e) => {
                            togglePopover(e)
                          }}
                          className="p-2 rounded-md bg-primary text-black font-semibold"
                        >
                          Kembali
                        </button>
                      ) : (
                        <button
                          onClick={() => forgotPassword()}
                          className="p-2 rounded-md bg-primary text-black font-semibold"
                        >
                          {loading ? "Loading..." : "Lanjutkan"}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Forgot Password End  */}
                </div>
                <div className="relative">
                  <GHButton
                    className=""
                    data-toggle-popover="daftar"
                    onClick={(e) => togglePopover(e)}
                  >
                    Sign Up
                  </GHButton>
                  <div
                    className={`${
                      popover === "daftar" ? "block" : "hidden"
                    } max-h-[85vh] transition-all absolute right-0 rounded-md bg-semiblack z-[999] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-primary scrollbar-thumb-rounded-md`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className="p-3 flex flex-col space-y-5 w-[350px] text-[0.75rem]">
                      <div className="text-[1.2rem] font-semibold">
                        Sign Up Account
                      </div>
                      <div>
                        Sudah memiliki akun Guehadir.id ?{" "}
                        <span
                          data-toggle-popover="masuk"
                          onClick={(e) => togglePopover(e)}
                          className=" text-primary cursor-pointer"
                        >
                          Masuk disini
                        </span>
                      </div>
                      <input
                        onChange={(e) => inputHandler(e)}
                        id="name"
                        className="p-2 bg-background outline-none"
                        placeholder="Nama Lengkap"
                      />
                      <input
                        onChange={(e) => inputHandler(e)}
                        id="telephone"
                        className="p-2 bg-background outline-none"
                        placeholder="No. Telephone"
                      />
                      <input
                        onChange={(e) => inputHandler(e)}
                        id="email"
                        className="p-2 bg-background outline-none"
                        placeholder="Email"
                      />
                      <div className="flex items-center justify-between p-2 bg-background">
                        <input
                          onChange={(e) => inputHandler(e)}
                          className="bg-transparent flex-1 outline-none"
                          id="password"
                          autoComplete="off"
                          autoCorrect="off"
                          type={pass}
                          placeholder="Kata Sandi"
                        />
                        {pass !== "" ? (
                          <EyeIcon
                            onClick={() => setPass("")}
                            className="h-[1rem] w-auto text-gray-400 cursor-pointer"
                          />
                        ) : (
                          <EyeSlashIcon
                            onClick={() => setPass("password")}
                            className="h-[1rem] w-auto text-gray-400 cursor-pointer"
                          />
                        )}
                      </div>
                      <div>*Kata sandi harus terdiri dari 8 karakter</div>
                      <div className="flex items-center justify-between p-2 bg-background">
                        <input
                          onChange={(e) => inputHandler(e)}
                          className="bg-transparent flex-1 outline-none"
                          id="password2"
                          autoComplete="off"
                          autoCorrect="off"
                          type={pass2}
                          placeholder="Konfirmasi Kata Sandi"
                        />
                        {pass2 !== "" ? (
                          <EyeIcon
                            onClick={() => setPass2("")}
                            className="h-[1rem] w-auto text-gray-400 cursor-pointer"
                          />
                        ) : (
                          <EyeSlashIcon
                            onClick={() => setPass2("password")}
                            className="h-[1rem] w-auto text-gray-400 cursor-pointer"
                          />
                        )}
                      </div>
                      <div>*Kedua kata sandi harus sama</div>
                      <div>
                        By continuing past this page, you agree to the{" "}
                        <span className="text-primary">Terms of Use</span> and
                        understand that information will be used as described in
                        our <span className="text-primary">Privacy Policy</span>
                        .
                      </div>
                      <button
                        onClick={() => otpHandler()}
                        className="text-center p-3 rounded-md bg-primary text-black font-semibold"
                      >
                        {loading ? "Loading..." : "Daftar"}
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${
                      dropdown === "verifikasi"
                        ? "max-h-[200rem]"
                        : "max-h-0 overflow-hidden"
                    } transition-all absolute right-0 rounded-md bg-semiblack z-[999] `}
                  >
                    <div className="p-3 flex flex-col space-y-5 w-[350px] text-[0.75rem]">
                      <div className="text-[1.2rem] font-semibold">
                        Verifikasi Akun
                      </div>
                      <div>
                        Kami telah mengirimkan kode verifikasi ke{" "}
                        <span className="text-primary font-semibold">
                          {inputData.email}
                        </span>{" "}
                        mohon periksa email kamu.
                      </div>
                      <div className="px-5">
                        <OtpInput
                          inputStyle="inputStyle"
                          value={otpCode}
                          onChange={(otp) => setOtpCode(otp)}
                          numInputs={4}
                          separator={
                            <span className="w-[20px] flex justify-center">
                              .
                            </span>
                          }
                        />
                      </div>
                      <button
                        onClick={() => registerHandler()}
                        className="text-center p-3 rounded-md bg-primary text-black font-semibold"
                      >
                        {loading ? "Loading..." : "Verifikasi"}
                      </button>
                      <div>
                        Tidak mendapatkan kode verifikasi ?{" "}
                        <span
                          onClick={() => otpHandler()}
                          className="text-primary font-semibold cursor-pointer"
                        >
                          Kirim Ulang
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <img
            onClick={() =>
              user
                ? dropdownMobileHander("profile")
                : dropdownMobileHander("masuk")
            }
            className="lg:hidden cursor-pointer w-6 h-6"
            src={User}
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default Navigation
